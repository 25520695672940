import jsPDF from 'jspdf';
import html2canvas from 'html2canvas-pro';

/**
 * Genera y descarga un PDF basado en el contenido de un elemento HTML.
 * @param targetId - ID del elemento HTML a convertir en PDF.
 * @param fileName - Nombre del archivo PDF.
 */
export const downloadPdf = (targetId: string, fileName: string = 'document.pdf') => {
  const A4_WIDTH_PX = 794; // Ajusta según tu configuración
  const A4_HEIGHT_PX = 1123;

  const input = document.getElementById(targetId);

  if (!input) {
    console.error(`Elemento con id "${targetId}" no encontrado.`);
    return;
  }

  input.classList.remove('hidden'); // Elimina la clase 'hidden'

  html2canvas(input, { scale: 2 }) // Ajusta el escalado si es necesario
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'px', [A4_WIDTH_PX, A4_HEIGHT_PX]);
      pdf.addImage(imgData, 'PNG', 0, 0, A4_WIDTH_PX, A4_HEIGHT_PX);
      pdf.save(fileName);

      input.classList.add('hidden'); // Vuelve a agregar la clase 'hidden'
    })
    .catch((error) => {
      console.error('Error al generar el PDF:', error);
    });
};
