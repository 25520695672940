import * as React from 'react';

type IconLinkedinProps = React.SVGProps<SVGSVGElement>;

export const IconLinkedin: React.FC<IconLinkedinProps> = (props) => (
	<svg width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.03759 0.989197H0.539662C0.241917 0.989197 0 1.24781 0 1.56529V8.4035C0 8.72097 0.241917 8.97959 0.539662 8.97959H7.03759C7.33534 8.97959 7.57895 8.72097 7.57895 8.4035V1.56529C7.57895 1.24781 7.33534 0.989197 7.03759 0.989197ZM2.2906 7.83811H1.16729V4.02483H2.29229V7.83811H2.2906ZM1.72895 3.50403C1.36861 3.50403 1.07763 3.19547 1.07763 2.81736C1.07763 2.43924 1.36861 2.13068 1.72895 2.13068C2.08759 2.13068 2.38026 2.43924 2.38026 2.81736C2.38026 3.19726 2.08929 3.50403 1.72895 3.50403ZM6.50132 7.83811H5.37801V5.98319C5.37801 5.54087 5.36955 4.97191 4.79436 4.97191C4.20902 4.97191 4.11936 5.45347 4.11936 5.95109V7.83811H2.99605V4.02483H4.07368V4.54564H4.08891C4.23947 4.246 4.60658 3.9303 5.15301 3.9303C6.28985 3.9303 6.50132 4.72043 6.50132 5.74776V7.83811Z" fill="#1A2228"/>
</svg>

);
// export default IconLinkedin;

