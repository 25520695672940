import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import papper from "../../../assets/images/papper.svg";
import circleCheckGray from "../../../assets/images/circle-check-solid-gray.svg";
import circleCheckOrange from "../../../assets/images/circle-check-solid-orange.svg";
import circleCheckRegular from "../../../assets/images/circle-check-regular.svg";
import "./OurServicesReport.css";
import ModalServicesDetail, {
  IDetailOurServices,
} from "../ModalServicesDetail";
import { TExporterCategory } from "../../../pages/Information/FinalReport/finalReport";

type IPropsOurServices = {
  name: string;
  level: number;
};

const EXPORTER_CATEGORY: Record<TExporterCategory, number> = {
  expert: 3,
  consolidated: 2,
  emergent: 1,
};

const OurServicesReport = ({ name, level }: IPropsOurServices) => {
  const { t } = useTranslation();
  const [showDetail, setShowModalDetail] = useState(false);
  const [detailIndex, setDetailIndex] = useState(0);

  const isServiceItemAvailable = useCallback(
    (levelsAvailable: Array<number>) => {
      return levelsAvailable.includes(level);
    },
    [level]
  );

  const servicesList: Array<IDetailOurServices> = useMemo(
    () => [
      {
        title: t("finalReport.services.item_1.title"),
        subtitle: t("finalReport.services.item_1.subtitle"),
        items: [
          {
            name: t("finalReport.services.item_1.name_1"),
            available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent, EXPORTER_CATEGORY.consolidated, EXPORTER_CATEGORY.expert]),
            subItems: [
              {
                name: t("services.tab_1.competitionSurveyInEuropeList.item_1"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
              {
                name: t("services.tab_1.competitionSurveyInEuropeList.item_2"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
              {
                name: t("services.tab_1.competitionSurveyInEuropeList.item_3"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
              {
                name: t("services.tab_1.competitionSurveyInEuropeList.item_4"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_1.competitionSurveyInEuropeList.item_5"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_1.competitionSurveyInEuropeList.item_6"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_1.competitionSurveyInEuropeList.item_7"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_1.competitionSurveyInEuropeList.item_8"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_1.competitionSurveyInEuropeList.item_9"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_1.competitionSurveyInEuropeList.item_10"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent
                ]),
              },
            ],
          },
          {
            name: t("finalReport.services.item_1.name_2"),
            available: isServiceItemAvailable([
              EXPORTER_CATEGORY.emergent,
              EXPORTER_CATEGORY.consolidated,
            ]),
            subItems: [
              {
                name: t("services.tab_1.competitionSurveyList.item_1"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
              {
                name: t("services.tab_1.competitionSurveyList.item_2"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
              {
                name: t("services.tab_1.competitionSurveyList.item_3"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
              {
                name: t("services.tab_1.competitionSurveyList.item_4"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
              {
                name: t("services.tab_1.competitionSurveyList.item_5"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
            ],
          },
          {
            name: t("finalReport.services.item_1.name_3"),
            available: isServiceItemAvailable([
              EXPORTER_CATEGORY.emergent,
              EXPORTER_CATEGORY.consolidated,
              EXPORTER_CATEGORY.expert,
            ]),
            subItems: [
              {
                name: t("services.tab_1.marketInformationInEuropeList.item_1"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_1.marketInformationInEuropeList.item_2"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_1.marketInformationInEuropeList.item_3"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
            ],
          },
          {
            name: t("finalReport.services.item_1.name_4"),
            available: isServiceItemAvailable([
              EXPORTER_CATEGORY.emergent,
              EXPORTER_CATEGORY.consolidated,
            ]),
            subItems: [
              {
                name: t("services.tab_1.priceAnalysisList.item_1"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_1.priceAnalysisList.item_2"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_1.priceAnalysisList.item_3"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_1.priceAnalysisList.item_4"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
            ],
          },
          {
            name: t("finalReport.services.item_1.name_5"),
            available: isServiceItemAvailable([
              EXPORTER_CATEGORY.emergent,
              EXPORTER_CATEGORY.consolidated,
            ]),
            subItems: [
              {
                name: t(
                  "services.tab_1.commercialDefinitionsForCustomersList.item_1"
                ),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
              {
                name: t(
                  "services.tab_1.commercialDefinitionsForCustomersList.item_2"
                ),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t(
                  "services.tab_1.commercialDefinitionsForCustomersList.item_3"
                ),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t(
                  "services.tab_1.commercialDefinitionsForCustomersList.item_4"
                ),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t(
                  "services.tab_1.commercialDefinitionsForCustomersList.item_5"
                ),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
            ],
          },
        ],
      },
      {
        title: t("finalReport.services.item_2.title"),
        subtitle: t("finalReport.services.item_1.subtitle"),
        items: [
          {
            name: t("finalReport.services.item_2.name_1"),
            available: isServiceItemAvailable([
              EXPORTER_CATEGORY.emergent,
              EXPORTER_CATEGORY.consolidated,
            ]),
            subItems: [
              {
                name: t("services.tab_2.healthLegalAnalysisList.item_1"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.healthLegalAnalysisList.item_2"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
              {
                name: t("services.tab_2.healthLegalAnalysisList.item_3"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.healthLegalAnalysisList.item_4"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.healthLegalAnalysisList.item_5"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.healthLegalAnalysisList.item_6"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.healthLegalAnalysisList.item_7"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.healthLegalAnalysisList.item_8"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.healthLegalAnalysisList.item_9"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.healthLegalAnalysisList.item_10"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.healthLegalAnalysisList.item_11"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.healthLegalAnalysisList.item_12"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
            ],
          },
          {
            name: t("finalReport.services.item_2.name_2"),
            available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
            subItems: [
              {
                name: t("services.tab_2.customsRequirementsList.item_1"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.customsRequirementsList.item_2"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.customsRequirementsList.item_3"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.customsRequirementsList.item_4"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.customsRequirementsList.item_5"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
            ],
          },
          {
            name: t("finalReport.services.item_2.name_3"),
            available: isServiceItemAvailable([
              EXPORTER_CATEGORY.emergent,
              EXPORTER_CATEGORY.consolidated,
            ]),
            subItems: [
              {
                name: t("services.tab_2.qualityRequirementsList.item_1"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.qualityRequirementsList.item_2"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
              {
                name: t("services.tab_2.qualityRequirementsList.item_3"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
            ],
          },
          {
            name: t("finalReport.services.item_2.name_4"),
            available: isServiceItemAvailable([
              EXPORTER_CATEGORY.emergent,
              EXPORTER_CATEGORY.consolidated,
            ]),
            subItems: [
              {
                name: t("services.tab_2.logisticsInformationList.item_1"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.logisticsInformationList.item_2"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.logisticsInformationList.item_3"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
              {
                name: t("services.tab_2.logisticsInformationList.item_4"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_2.logisticsInformationList.item_5"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
            ],
          },
        ],
      },
      {
        title: t("finalReport.services.item_3.title"),
        subtitle: t("finalReport.services.item_1.subtitle"),
        items: [
          {
            name: t("finalReport.services.item_3.name_1"),
            available: isServiceItemAvailable([
              EXPORTER_CATEGORY.emergent,
              EXPORTER_CATEGORY.consolidated,
              EXPORTER_CATEGORY.expert,
            ]),
            subItems: [
              {
                name: t("services.tab_3.newEURegulatoryFrameworkList.item_1"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_3.newEURegulatoryFrameworkList.item_2"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
              {
                name: t("services.tab_3.newEURegulatoryFrameworkList.item_3"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert
                ]),
              },
              {
                name: t("services.tab_3.newEURegulatoryFrameworkList.item_4"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_3.newEURegulatoryFrameworkList.item_5"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_3.newEURegulatoryFrameworkList.item_6"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_3.newEURegulatoryFrameworkList.item_7"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_3.newEURegulatoryFrameworkList.item_8"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_3.newEURegulatoryFrameworkList.item_9"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_3.newEURegulatoryFrameworkList.item_10"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
            ],
          },
          {
            name: t("finalReport.services.item_3.name_2"),
            available: isServiceItemAvailable([
              EXPORTER_CATEGORY.emergent,
              EXPORTER_CATEGORY.consolidated,
              EXPORTER_CATEGORY.expert,
            ]),
            subItems: [
              {
                name: t("services.tab_3.complianceList.item_1"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_3.complianceList.item_2"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_3.complianceList.item_3"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_3.complianceList.item_4"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_3.complianceList.item_5"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_3.complianceList.item_6"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_3.complianceList.item_7"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_3.complianceList.item_8"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
              {
                name: t("services.tab_3.complianceList.item_9"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                  EXPORTER_CATEGORY.expert,
                ]),
              },
            ],
          },
          {
            name: t("finalReport.services.item_3.name_3"),
            available: isServiceItemAvailable([
              EXPORTER_CATEGORY.emergent,
              EXPORTER_CATEGORY.consolidated,
            ]),
            subItems: [
              {
                name: t("services.tab_3.otherList.item_1"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_3.otherList.item_2"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_3.otherList.item_3"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_3.otherList.item_4"),
                available: isServiceItemAvailable([
                  EXPORTER_CATEGORY.emergent,
                  EXPORTER_CATEGORY.consolidated,
                ]),
              },
              {
                name: t("services.tab_3.otherList.item_5"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
              {
                name: t("services.tab_3.otherList.item_6"),
                available: isServiceItemAvailable([EXPORTER_CATEGORY.emergent]),
              },
            ],
          },
        ],
      },
    ],
    [t, isServiceItemAvailable]
  );

  const openDetailService = (index: number) => {
    setShowModalDetail(!showDetail);
    setDetailIndex(index);
  };

  const handleCloseModalServiceDetail = () => {
    setShowModalDetail(false);
  };

  const getAvailableServicesList = useCallback(
    (index: number) => {
      return servicesList[index];
    },
    [servicesList]
  );

  return (
    <>
      
        <div className="main-section-container main-bottom">
          <h5 className="section-subtitle">{t("finalReport.services.title")}</h5>
          <div className="services-cards-container">
            {servicesList.map((item, index) => (
              <div className="card-service-item-container">
                <img className="papper-icon" src={papper} alt="papper" />
                <h4>{item.title}</h4>
                <div>
                  {item.items.map((option) => (
                    <div className="option-check-container">
                      {option.available ? (
                        <img src={circleCheckOrange} alt="check" />
                      ) : (
                        <img src={circleCheckRegular} alt="check" />
                      )}
                      <p>{option.name}</p>
                    </div>
                  ))}
                </div>
                <div>
                  <div
                    className="see-details-link"
                    onClick={() => openDetailService(index)}
                  >
                    <p>{t("finalReport.services.footer.link")}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      

      <ModalServicesDetail
        title="Modal with Collapsible Items"
        detail={getAvailableServicesList(detailIndex)}
        show={showDetail}
        handleClose={handleCloseModalServiceDetail}
      />
    </>
  );
};

export default OurServicesReport;
