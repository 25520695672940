import * as React from 'react';

type IconInstagramProps = React.SVGProps<SVGSVGElement>;

export const IconInstagram: React.FC<IconInstagramProps> = (props) => (
	<svg width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.00089 2.93576C2.86581 2.93576 1.95025 3.85042 1.95025 4.9844C1.95025 6.11837 2.86581 7.03303 4.00089 7.03303C5.13597 7.03303 6.05153 6.11837 6.05153 4.9844C6.05153 3.85042 5.13597 2.93576 4.00089 2.93576ZM4.00089 6.31628C3.26737 6.31628 2.66771 5.71898 2.66771 4.9844C2.66771 4.24981 3.26559 3.65251 4.00089 3.65251C4.7362 3.65251 5.33408 4.24981 5.33408 4.9844C5.33408 5.71898 4.73441 6.31628 4.00089 6.31628ZM6.61372 2.85196C6.61372 3.11762 6.39955 3.3298 6.13542 3.3298C5.86949 3.3298 5.65711 3.11584 5.65711 2.85196C5.65711 2.58808 5.87128 2.37412 6.13542 2.37412C6.39955 2.37412 6.61372 2.58808 6.61372 2.85196ZM7.97189 3.33693C7.94155 2.69684 7.7952 2.12985 7.32582 1.66272C6.85823 1.19558 6.29069 1.04937 5.64997 1.01728C4.98963 0.979836 3.01037 0.979836 2.35003 1.01728C1.7111 1.04759 1.14356 1.19379 0.674177 1.66093C0.204796 2.12807 0.0602342 2.69506 0.0281093 3.33514C-0.00936977 3.99484 -0.00936977 5.97216 0.0281093 6.63186C0.0584495 7.27195 0.204796 7.83894 0.674177 8.30608C1.14356 8.77321 1.70931 8.91942 2.35003 8.95151C3.01037 8.98895 4.98963 8.98895 5.64997 8.95151C6.29069 8.9212 6.85823 8.775 7.32582 8.30608C7.79342 7.83894 7.93977 7.27195 7.97189 6.63186C8.00937 5.97216 8.00937 3.99663 7.97189 3.33693ZM7.1188 7.3397C6.97959 7.68917 6.7101 7.9584 6.35851 8.09925C5.83201 8.30786 4.58271 8.25972 4.00089 8.25972C3.41907 8.25972 2.16799 8.30608 1.64328 8.09925C1.29347 7.96018 1.02398 7.69095 0.882989 7.3397C0.674177 6.81373 0.722365 5.56564 0.722365 4.9844C0.722365 4.40315 0.675962 3.15328 0.882989 2.62909C1.0222 2.27962 1.29169 2.01039 1.64328 1.86954C2.16977 1.66093 3.41907 1.70907 4.00089 1.70907C4.58271 1.70907 5.8338 1.66272 6.35851 1.86954C6.70831 2.00861 6.9778 2.27784 7.1188 2.62909C7.32761 3.15506 7.27942 4.40315 7.27942 4.9844C7.27942 5.56564 7.32761 6.81551 7.1188 7.3397Z" fill="#1A2228"/>
</svg>


);
// export default IconInstagram;

