import React from "react";
import { useTranslation } from 'react-i18next';
import "./ourTools.css";
import chevronRight from "../../../assets/images/chevron-right.svg";
import { useNavigate } from "react-router-dom";
import calculadora from "../../../assets/images/calculadora.svg";
import calculadoraPrecios from "../../../assets/images/calculadora-precios.svg";
import comparadorPrecios from "../../../assets/images/comparador-precios.svg";

const OutTools = () => {
  const {t} = useTranslation()
  const navigate = useNavigate();

  const toolsList = [
    {
      image: calculadora,
      title: t('finalReport.tools.item_1.title'),
      description: t('finalReport.tools.item_1.description'),
      color: "grey",
      link: "/cubicaje",
    },
    {
      image: calculadoraPrecios,
      title: t('finalReport.tools.item_2.title'),
      description: t('finalReport.tools.item_2.description'),
      color: "green",
      link: "/calcularprecios",
    },
    {
      image: comparadorPrecios,
      title: t('finalReport.tools.item_3.title'),
      description: t('finalReport.tools.item_3.description'),
      color: "white",
      link: "/pricecomparator",
    },
  ];

  return (
    <>
      <div className="main-section-container">
        <h5 className="section-subtitle">{t('finalReport.tools.title')}</h5>
        <div className="mainCardsContainer">
          {toolsList.map((item) => (
            <div className="toolItemContainer">
              <div
                className={`cardItemContainer ${
                  item.color === "grey"
                    ? "cardItemContainerGrey"
                    : item.color === "green"
                    ? "cardItemContainerGreen"
                    : "cardItemContainerWhite"
                }`}
              >
                <img src={item.image} alt="tool" />
              </div>
              <div
                className={`textsContainer ${
                  item.color === "grey"
                    ? "textsContainerGrey"
                    : item.color === "green"
                    ? "textsContainerGreen"
                    : "textsContainerWhite"
                }`}
              >
                <h6>{item.title}</h6>
                <div
                  className="bottomContentContainer"
                  onClick={() => navigate(item.link)}
                >
                  <p>{item.description}</p>
                  <div className="chevronIconContainer">
                    <img src={chevronRight} alt="chevron-icon" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default OutTools;
