
// Función para reemplazar los asteriscos con etiquetas <strong>
export const formatText = (text='') => {
    // Reemplazar los asteriscos con <span>
    const formattedText = text.replace(/\*\*(.+?)\*\*/g, "<span>$1</span>");
    // Dividir el texto en párrafos usando dobles saltos de línea como delimitador
    const paragraphs = formattedText.split(/\n+/).map(paragraph => `<p>${paragraph.trim()}</p>`);
    // Unir los párrafos en un solo string
    return paragraphs.join('');
};


