/* eslint-disable react/style-prop-object */
import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faMagnifyingGlass,
  faBookmark,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faLinkedin,
  faSquareFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import logo from "../../assets/images/logo.svg";
import perfil from "../../assets/images/perfil.svg";
import preferencias from "../../assets/images/preferencias.svg";
import guardados from "../../assets/images/guardados.svg";
import notificaciones from "../../assets/images/notificaciones.svg";
import servicios from "../../assets/images/servicios.svg";
import nosotros from "../../assets/images/nosotros.svg";
import suggestions from "../../assets/images/suggestions.svg";
import "./Header.css";
import i18n from "../../i18n";
import { Button } from "@mui/material";
import {IconExporterTest, IconCubicaje, IconCalcularPrecios, IconPriceComparator, IconPreferencias, IconLogo, IconLogoMobile} from "../Icons";
import HeaderDropdownMobile from "./HeaderDropdownMobile";
import { useGetApiNews } from "../../api/news.api";



const Header: FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const [isLandingPage, setIsLandingPage] = useState(true);
  const [isDropdownUserOpen, setIsDropdownUserOpen] = useState(false);
  const [isDropdownCompanyOpen, setIsDropdownCompanyOpen] = useState(false);
  const userFullName = localStorage.getItem("alias");
  const userEmail = localStorage.getItem("username");
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const { data: dataNewsList, mutate: newsList } = useGetApiNews({}); // Pass an empty object as the argument to the newsList // Manejar cambios en el input
  const handleChange = (e: { target: { value: any; }; }) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Llamar a la API si hay 3 o más caracteres
    if (value.length >= 3) {
      newsList({}); // Pasamos un objeto vacío
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && searchTerm.length >= 3) {
      navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
      setSearchTerm(""); // Limpiar el input después de la búsqueda
    }
  };
  

  
  // Filtrar noticias por título
  const filteredNews = searchTerm.length >= 3
    ? dataNewsList?.filter((news) =>
        news.title.toLowerCase().includes(searchTerm.toLowerCase())
      ) || []
    : [];

  // Redirigir al hacer clic en una noticia
  const handleClick = (id: any) => {
    setSearchTerm(""); // Limpiar el input
    navigate(`/noticia/${id}`); // Redirige a la página de la noticia
  };
  
  
  const handleLogout = () => {
    setIsDropdownUserOpen(false);
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    setIsLandingPage(true);
    navigate("/home");
  };

  const handleLogIn = () => {
    navigate("/login");
    setIsLandingPage(false);
  };

  const handleHome = () => {
    navigate("/home");
    setIsLandingPage(true);
  };

  const handleDropdownCompanyToggle = () => {
    setIsDropdownCompanyOpen((prevState) => !prevState);
    setIsDropdownUserOpen(false);
  };

  const handleDropdownUserToggle = () => {
    setIsDropdownUserOpen((prevState) => !prevState);
    setIsDropdownCompanyOpen(false);
  };

  return (
    <>
      {isAuthenticated ? (
        <header className="navbar navbar-expand-lg">
          <div className="navbar__row">
              <div className={"header__top"}>
                <div
                  className=" header__logo"
                  onMouseEnter={handleDropdownCompanyToggle}
                >
                
                    <IconLogo className={`hide-mobile interconectar-menu ${
                      isAuthenticated ? "" : "interconectar-menu-login"
                    }`} />

                    <IconLogoMobile  className="hide-desktop " />
                
                    {isAuthenticated && (
                        <FontAwesomeIcon
                          icon={isDropdownCompanyOpen ? faCaretUp : faCaretDown}
                          color="#FFFFFF"
                        />
                      )}
                      
                </div>
                
                <div className="header__right">
                      

                {isAuthenticated && (
                  <>
                    <div className="frame-20 header__home" onClick={handleHome}>
                      <FontAwesomeIcon icon={faHome} color="#e3efea" />
                    </div>
                    <div className="header__search">
                    <input
                      type="text"
                      className="buscar-noticia header__search__input"
                      placeholder="Buscar noticia..."
                      value={searchTerm}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown} // Agregar el evento para detectar Enter

                      />
                      {filteredNews.length > 0 && (
                        <ul className="filter-search">
                          {filteredNews.map((news) => (
                            <li
                              key={news.id}
                              className="filter-search__item"
                              onClick={() => handleClick(news.id)}
                            >
                              <div className="filter-search__figure">
                                <img  src={news.image} alt={news.title} />
                              </div>
                              <div className="filter-search__data">

                              <h2 className="search__title">{news.title}</h2>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                      <div className="frame-21">
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          color="#FFFFFF"
                        />
                      </div>
                    </div>
                    <HeaderDropdownMobile />
                    <div className="header__tools">
                      <div className="header__tools__text">{t("header.tools")}</div>
                      <div className="header__tools__block">
                        <Link to="/exportertest">
                          <IconExporterTest fill="#425563"  />
                        </Link>
                      </div>
                      <div className="header__tools__block">
                        <Link to="/cubicaje">
                          <IconCubicaje />
                        </Link>
                      </div>
                      <div className="header__tools__block">
                        <Link to="/calcularprecios">
                          <IconCalcularPrecios />
                        </Link>
                      </div>
                      <div className="header__tools__block">
                        <Link to="/pricecomparator">
                          <IconPriceComparator />
                        </Link>
                      </div>
                    </div>
                    <div
                      className={`${
                        isAuthenticated ? "header__account" : "frame-login"
                      }`}
                      onMouseEnter={handleDropdownUserToggle}
                    >
                      <div className="header__account__text">{t("header.account")}</div>
                      <div className="header__account__preferences">
                        <IconPreferencias />
                      </div>
                      <div className="header__account__bookmark">
                        <FontAwesomeIcon icon={faBookmark} color="#425563" />
                      </div>
                      <div className="header__user">
                        <div className="frame-31">
                          <div className="d">{userFullName?.charAt(0)}</div>
                        </div>
                        <FontAwesomeIcon
                          icon={isDropdownUserOpen ? faCaretUp : faCaretDown}
                          color="#FFFFFF"
                        />
                      </div>
                    </div>
                  </>
                )}
                {isAuthenticated && isDropdownCompanyOpen && (
                  <div
                    onMouseLeave={handleDropdownCompanyToggle}
                    className={`dropdown-menu-company ${
                      isDropdownCompanyOpen ? "open" : ""
                    }`}
                  >
                    <div className="frame-80">
                      <div
                        className="frame-79"
                        onClick={() => navigate("/acerca-interconectar")}
                      >
                        <img src={nosotros} alt="Link Solid" />
                        <div className="text-btn">{t("header.about")}</div>
                      </div>
                      <div
                        className="frame-78"
                        onClick={() => navigate("/servicios")}
                      >
                        <img src={servicios} alt="Link Solid" />
                        <div className="text-btn">{t("header.services")}</div>
                      </div>
                      <div className="frame-81">
                        <img src={suggestions} alt="Link Solid" />
                        <div className="text-btn">
                          {t("header.suggestions")}
                        </div>
                      </div>
                    </div>
                    <div className="frame-83">
                      <div className="frame-98">
                        <FontAwesomeIcon icon={faInstagram} />
                      </div>
                      <div className="frame-99">
                        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/exportopia/">
                          <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                      </div>
                      <div className="frame-100">
                        <FontAwesomeIcon icon={faSquareFacebook} />
                      </div>
                      <div className="frame-101">
                        <a target="_blank" rel="noreferrer" href="https://youtube.com/@exportopiaoficial?si=27tsAYLZOajDJI2q">
                          <FontAwesomeIcon icon={faYoutube} />
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                {isDropdownUserOpen && (
                  <div
                    onMouseLeave={handleDropdownUserToggle}
                    className={`dropdown__user ${
                      isDropdownUserOpen ? "open" : ""
                    }`}
                  >
                    <div className="dropdown__user__header">
                      <div className="dropdown__user__name">
                        <div className="dropdown__user__icon ">
                          <div className="dropdown__user__inner ">
                            {userFullName?.charAt(0)}
                          </div>
                        </div>
                        <div className="dropdown__user__language ">
                          {/* <img
                        src="https://cdn-icons-png.flaticon.com/512/197/197593.png"
                        alt="ES"
                      /> */}
                          <Button
                            onClick={() => i18n.changeLanguage("es")}
                            style={{
                              margin: "0",
                              padding: "0",
                              minWidth: "auto",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 256 256"
                            >
                              <g
                                style={{
                                  stroke: "none",
                                  strokeWidth: 0,
                                  strokeDasharray: "none",
                                  strokeLinecap: "butt",
                                  strokeLinejoin: "miter",
                                  strokeMiterlimit: 10,
                                  fill: "none",
                                  fillRule: "nonzero",
                                  opacity: 1,
                                }}
                              >
                                <path
                                  d="M0 45c0 8.199 2.202 15.88 6.034 22.5h77.932A44.767 44.767 0 0 0 90 45c0-8.199-2.202-15.88-6.034-22.5H6.034A44.767 44.767 0 0 0 0 45z"
                                  style={{
                                    stroke: "none",
                                    strokeWidth: 1,
                                    strokeDasharray: "none",
                                    strokeLinecap: "butt",
                                    strokeLinejoin: "miter",
                                    strokeMiterlimit: 10,
                                    fill: "#ffc400",
                                    fillRule: "nonzero",
                                    opacity: 1,
                                  }}
                                  transform="matrix(2.81 0 0 2.81 1.407 1.407)"
                                />
                                <path
                                  d="M83.966 22.5a45.903 45.903 0 0 0-.831-1.381C75.176 8.438 61.077 0 45 0a44.733 44.733 0 0 0-22.5 6.04A45.242 45.242 0 0 0 6.034 22.5h77.932zM6.034 67.5A45.251 45.251 0 0 0 22.5 83.96 44.733 44.733 0 0 0 45 90c16.077 0 30.176-8.438 38.135-21.12.285-.455.562-.915.831-1.38H6.034z"
                                  style={{
                                    stroke: "none",
                                    strokeWidth: 1,
                                    strokeDasharray: "none",
                                    strokeLinecap: "butt",
                                    strokeLinejoin: "miter",
                                    strokeMiterlimit: 10,
                                    fill: "#c60b1e",
                                    fillRule: "nonzero",
                                    opacity: 1,
                                  }}
                                  transform="matrix(2.81 0 0 2.81 1.407 1.407)"
                                />
                              </g>
                            </svg>
                          </Button>

                          <Button
                            onClick={() => i18n.changeLanguage("en")}
                            style={{
                              margin: "0",
                              padding: "0",
                              minWidth: "auto",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 256 256"
                            >
                              <g
                                style={{
                                  stroke: "none",
                                  strokeWidth: 0,
                                  strokeDasharray: "none",
                                  strokeLinecap: "butt",
                                  strokeLinejoin: "miter",
                                  strokeMiterlimit: 10,
                                  fill: "none",
                                  fillRule: "nonzero",
                                  opacity: 1,
                                }}
                              >
                                <path
                                  d="M88.35 57.052c.034-.123.076-.243.109-.367l-.004-.002a44.92 44.92 0 0 0 .007-23.34l.004-.002c-.039-.146-.088-.289-.128-.434a43.39 43.39 0 0 0-.434-1.468c-.081-.257-.167-.512-.253-.768-.073-.217-.139-.437-.215-.653h-.015a44.92 44.92 0 0 0-7.01-12.768L59.997 27.458V2.57A44.864 44.864 0 0 0 46.082.028h-2.164a44.885 44.885 0 0 0-13.913 2.541v24.889L9.589 17.249a44.915 44.915 0 0 0-7.01 12.769h-.015c-.076.216-.143.436-.216.653-.086.255-.172.509-.253.765-.154.486-.297.977-.434 1.47-.04.145-.089.287-.128.432l.004.002a44.978 44.978 0 0 0 .008 23.344l-.004.002c.033.123.074.242.108.365.146.524.298 1.046.462 1.562.075.236.154.47.233.705.077.231.148.464.229.693h.017a44.9 44.9 0 0 0 7.016 12.761l20.4-10.2v24.86A44.927 44.927 0 0 0 45 90c5.26 0 10.305-.911 14.997-2.57V62.572l20.398 10.199a44.905 44.905 0 0 0 7.015-12.76h.015c.081-.229.152-.463.23-.694.079-.234.158-.468.233-.704.164-.517.317-1.038.462-1.561z"
                                  style={{
                                    stroke: "none",
                                    strokeWidth: 1,
                                    strokeDasharray: "none",
                                    strokeLinecap: "butt",
                                    strokeLinejoin: "miter",
                                    strokeMiterlimit: 10,
                                    fill: "#f3f4f5",
                                    fillRule: "nonzero",
                                    opacity: 1,
                                  }}
                                  transform="matrix(2.81 0 0 2.81 1.407 1.407)"
                                />
                                <path
                                  d="M53.999.902a45.128 45.128 0 0 0-7.917-.874h-2.164a45.19 45.19 0 0 0-7.914.874v35.116H.899A45.197 45.197 0 0 0 0 45c0 3.087.312 6.1.904 9.012h35.1v35.087A45.19 45.19 0 0 0 45 90c3.082 0 6.091-.311 8.999-.902V54.012h35.097C89.688 51.1 90 48.087 90 45c0-3.076-.311-6.08-.899-8.983H53.999V.902z"
                                  style={{
                                    stroke: "none",
                                    strokeWidth: 1,
                                    strokeDasharray: "none",
                                    strokeLinecap: "butt",
                                    strokeLinejoin: "miter",
                                    strokeMiterlimit: 10,
                                    fill: "#c00",
                                    fillRule: "nonzero",
                                    opacity: 1,
                                  }}
                                  transform="matrix(2.81 0 0 2.81 1.407 1.407)"
                                />
                                <path
                                  d="M85.242 65.135a44.765 44.765 0 0 0 2.184-5.125H74.993l10.249 5.125zM82.216 19.701 61.581 30.019h13.412l10.261-5.131a45.064 45.064 0 0 0-3.038-5.187zM4.747 24.887a44.682 44.682 0 0 0-2.182 5.132H15.01L4.747 24.887zM7.8 70.321 28.422 60.01H15.01L4.758 65.136A45.042 45.042 0 0 0 7.8 70.321z"
                                  style={{
                                    stroke: "none",
                                    strokeWidth: 1,
                                    strokeDasharray: "none",
                                    strokeLinecap: "butt",
                                    strokeLinejoin: "miter",
                                    strokeMiterlimit: 10,
                                    fill: "#c00",
                                    fillRule: "nonzero",
                                    opacity: 1,
                                  }}
                                  transform="matrix(2.81 0 0 2.81 1.407 1.407)"
                                />
                                <path
                                  d="m9.589 17.249 20.416 10.208V2.584A45.071 45.071 0 0 0 9.589 17.249zM59.997 2.585v24.872L80.411 17.25A45.06 45.06 0 0 0 59.997 2.585zM30.006 72.77V62.572l-20.4 10.2a45.072 45.072 0 0 0 20.4 14.646V72.77zM59.997 62.572v24.844A45.072 45.072 0 0 0 80.395 72.77L59.997 62.572z"
                                  style={{
                                    stroke: "none",
                                    strokeWidth: 1,
                                    strokeDasharray: "none",
                                    strokeLinecap: "butt",
                                    strokeLinejoin: "miter",
                                    strokeMiterlimit: 10,
                                    fill: "#006",
                                    fillRule: "nonzero",
                                    opacity: 1,
                                  }}
                                  transform="matrix(2.81 0 0 2.81 1.407 1.407)"
                                />
                              </g>
                            </svg>
                          </Button>
                          <FontAwesomeIcon icon={faCaretDown} color="white" />
                        </div>
                      </div>
                      <div className="dropdown__user__info">
                        <div className="dropdown__user__title">{userFullName}</div>
                        <div className="dropdown__user__email">{userEmail}</div>
                      </div>
                    </div>
                    <div className="dropdown__user__otions">
                      <div className="dropdown__user__option">
                        <div className="dropdown__user__inner">
                          <img src={perfil} alt="Link Solid" />
                        </div>
                        <div className="dropdown__user__text">
                          {t("header.profile.account")}
                        </div>
                      </div>
                      <Link
                        to="/preferencias"
                        className="link linkPreferencesText"
                      >
                        <div className="dropdown__user__option option">
                          <div className="dropdown__user__inner">
                            <img src={preferencias} alt="Link Solid" />
                          </div>
                          <div className="dropdown__user__text">
                            {t("header.profile.preferences")}
                          </div>
                        </div>
                      </Link>
                      <div className="dropdown__user__option option">
                        <div className="dropdown__user__inner">
                          <img src={guardados} alt="Link Solid" />
                        </div>
                        <div className="dropdown__user__text">{t("header.profile.saved")}</div>
                      </div>
                      <div className="dropdown__user__option option">
                        <div className="dropdown__user__inner">
                          <img src={notificaciones} alt="Link Solid" />
                        </div>
                        <div className="dropdown__user__text">
                          {t("header.profile.notifications")}
                        </div>
                      </div>
                    </div>
                    <div className="dropdown__user__footer">
                      <div className="dropdown__user__guide ">
                        {t("header.profile.guide")}
                        <div className="dropdown__user__inner icon-inner">
                          <img
                            src="https://static.vecteezy.com/system/resources/previews/009/592/988/large_2x/3d-rendering-blue-and-yellow-color-user-icon-isolated-free-png.png"
                            alt="guia"
                            height={"20px"}
                          />
                        </div>
                        <FontAwesomeIcon icon={faCaretDown} />
                      </div>
                      <Link
                        to="/home"
                        className="logout"
                        onClick={handleLogout}
                      >
                        {t("header.profile.logout")}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
              </div>
          </div>
        </header>
      ) : (
        <header className="navbar navbar navbar-expand-lg">
          <div className="navbar__row ">
                <div
                  className=" header__logo"
                  onMouseEnter={handleDropdownCompanyToggle}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    onClick={handleHome}
                    className={`interconectar-menu ${
                      isAuthenticated ? "" : "interconectar-menu-login"
                    }`}
                  />
                </div>
                {!isAuthenticated && (
                  <div className="d-flex">
                    <div className="header__btn-login" onClick={handleLogIn}>
                      <div>{t("header.login")}</div>
                    </div>
                    {/* <div className="language-icon">
                      <FontAwesomeIcon icon={faCaretDown} color="white" />
                    </div> */}
                  </div>
                )}

                {/* {!isAuthenticated && !isLandingPage && (
                  <div className="header__contenedor-img-arrow-lng">
                    <div className="language-icon">
                      <FontAwesomeIcon icon={faCaretDown} color="white" />
                    </div>
                  </div>
                )} */}
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
