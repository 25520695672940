import * as React from 'react';

type IconLogoProps = React.SVGProps<SVGSVGElement>;

export const IconLogo: React.FC<IconLogoProps> = (props) => (
	<svg
    width={178}
    height={44}
    viewBox="0 0 178 44"
    fill={'#fff'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_965_850)">
      <g>
      <path
        d="M18.2752 26.1082C15.2165 31.1372 12.126 35.3479 12.126 35.3479C12.126 35.3479 9.0382 31.1372 5.97687 26.1082C5.79414 25.8064 5.61142 25.5045 5.42869 25.1973C3.59613 28.4996 2.10254 31.8416 2.10254 33.9787C2.10254 39.5135 6.58861 43.9996 12.1234 43.9996C17.6581 43.9996 22.1468 39.5135 22.1468 33.9787C22.1468 31.8443 20.6532 28.4996 18.8207 25.1973C18.638 25.5045 18.4552 25.8064 18.2725 26.1082H18.2752Z"
        fill="#ADC9B8"
      />
      <path
        d="M12.1262 14.7876C12.1262 14.7876 14.679 18.2673 17.2081 22.4224C17.7562 23.3254 18.3018 24.2602 18.8235 25.1977C21.6306 20.4813 24.2523 15.2431 24.2523 12.1262C24.2523 5.42883 18.8235 0 12.1262 0C5.42883 0 0 5.42883 0 12.1262C0 15.2404 2.62438 20.4813 5.43148 25.1951C5.95053 24.2576 6.49871 23.3228 7.04689 22.4224C9.57593 18.2647 12.1288 14.7876 12.1288 14.7876H12.1262Z"
        fill="#FF5C35"
      />
      <path
        d="M12.126 35.3449C12.126 35.3449 15.2138 31.1342 18.2752 26.1053C18.4579 25.8034 18.6406 25.5015 18.8234 25.1943C18.3043 24.2568 17.7561 23.322 17.2079 22.419C14.6789 18.2613 12.126 14.7842 12.126 14.7842C12.126 14.7842 9.57316 18.2639 7.04412 22.419C6.49594 23.322 5.95041 24.2542 5.42871 25.1916C5.61144 25.4988 5.79416 25.8007 5.97689 26.1026C9.03822 31.1316 12.126 35.3422 12.126 35.3422V35.3449Z"
        fill="white"
      />
      </g>
      <path
        d="M45.2922 29.5982C43.7536 29.5982 42.3924 29.3228 41.214 28.7693C40.0329 28.2158 39.106 27.4373 38.436 26.4283C37.7634 25.4193 37.4297 24.2568 37.4297 22.9353V20.1732C37.4297 19.0027 37.7501 17.9249 38.3883 16.9398C39.0266 15.9546 39.8846 15.1628 40.9571 14.567C42.0296 13.9711 43.2266 13.6719 44.5481 13.6719C45.761 13.6719 46.8732 13.9314 47.8795 14.4531C48.8858 14.9721 49.7041 15.6872 50.3344 16.5981C50.962 17.5091 51.2772 18.5711 51.2772 19.7839C51.2772 20.7161 51.1262 21.3808 50.8217 21.7833C50.5171 22.1832 50.1331 22.4322 49.6671 22.5301C49.201 22.6281 48.719 22.6758 48.2211 22.6758H41.6218V23.1631C41.6218 24.0952 41.974 24.8473 42.6784 25.422C43.3829 25.9966 44.2991 26.2826 45.4246 26.2826C46.1396 26.2826 46.7355 26.1741 47.2122 25.9569C47.6889 25.7398 48.0993 25.5332 48.4462 25.3399C48.7932 25.1439 49.1189 25.0486 49.4208 25.0486C49.7465 25.0486 50.0272 25.1572 50.2656 25.3743C50.5039 25.5915 50.6893 25.8457 50.819 26.137C50.9488 26.4283 51.015 26.6852 51.015 26.8997C51.015 27.2678 50.782 27.6624 50.3159 28.0861C49.8498 28.5098 49.1877 28.8673 48.3324 29.1586C47.477 29.4526 46.4627 29.5982 45.2922 29.5982ZM41.6192 20.2024H46.3647C46.7328 20.2024 46.9871 20.1441 47.1274 20.0249C47.2678 19.9058 47.3393 19.6727 47.3393 19.3258C47.3393 18.8279 47.2095 18.383 46.95 17.9938C46.6905 17.6045 46.3436 17.2999 45.9093 17.0828C45.4749 16.8656 44.9983 16.757 44.4792 16.757C43.9602 16.757 43.5153 16.8603 43.081 17.0669C42.6467 17.2734 42.2944 17.5647 42.0243 17.9434C41.7542 18.3221 41.6192 18.7723 41.6192 19.2914V20.2024Z"
        
      />
      <path
        d="M55.4639 29.5982C55.1832 29.5982 54.831 29.4949 54.4073 29.2884C53.9836 29.0818 53.6102 28.817 53.2871 28.4913C52.9613 28.1655 52.7998 27.8424 52.7998 27.5167C52.7998 27.2784 52.8872 27.04 53.0593 26.8017L56.8939 21.4391L53.3189 16.4657C53.2765 16.3995 53.2209 16.3042 53.1573 16.1744C53.0938 16.0447 53.0593 15.9149 53.0593 15.7851C53.0593 15.4594 53.2156 15.1363 53.5307 14.8106C53.8432 14.4849 54.2192 14.2148 54.6509 13.9976C55.0852 13.7805 55.4851 13.6719 55.8532 13.6719C55.9618 13.6719 56.1127 13.6984 56.3087 13.754C56.5046 13.8096 56.6662 13.9314 56.796 14.1274L59.754 18.873L62.7438 14.1591C62.8524 13.9632 63.0034 13.8334 63.1993 13.7699C63.3953 13.7037 63.578 13.6719 63.7528 13.6719C64.0785 13.6719 64.4572 13.7805 64.8915 13.9976C65.3258 14.2148 65.6992 14.4796 66.0117 14.7947C66.3242 15.1099 66.4831 15.4409 66.4831 15.7851C66.4831 15.9811 66.3957 16.2089 66.2236 16.4684L62.6167 21.4735L66.4857 26.8043C66.5493 26.8706 66.6102 26.9659 66.6632 27.0956C66.7161 27.2254 66.7453 27.3658 66.7453 27.5194C66.7453 27.8239 66.5943 28.1311 66.2898 28.4462C65.9852 28.7614 65.6277 29.0315 65.2173 29.2592C64.8041 29.487 64.4043 29.6009 64.015 29.6009C63.8402 29.6009 63.6628 29.5691 63.4774 29.5029C63.292 29.4367 63.1464 29.3175 63.0378 29.1454L59.754 24.2038L56.5046 29.1454C56.3961 29.2963 56.2398 29.4102 56.0333 29.487C55.8267 29.5638 55.6387 29.6009 55.4639 29.6009V29.5982Z"
        
      />
      <path
        d="M71.0988 36.4888C70.5136 36.4888 70.0263 36.3485 69.637 36.0651C69.2477 35.7844 69.0518 35.4799 69.0518 35.1541V15.1655C69.0518 14.7762 69.2477 14.461 69.637 14.2227C70.0263 13.9844 70.503 13.8652 71.067 13.8652C71.6311 13.8652 72.0919 13.9844 72.4494 14.2227C72.8069 14.461 72.9844 14.7762 72.9844 15.1655V16.14C73.3525 15.5124 73.9165 14.943 74.6739 14.4346C75.4313 13.9261 76.3317 13.6719 77.3724 13.6719C78.4132 13.6719 79.4407 13.9711 80.3305 14.567C81.2176 15.1628 81.9168 15.9599 82.4279 16.9557C82.9363 17.9514 83.1906 19.0583 83.1906 20.2712V22.9353C83.1906 24.0846 82.931 25.1678 82.4093 26.1847C81.8903 27.2042 81.1964 28.0278 80.3305 28.6554C79.4645 29.2831 78.5218 29.5982 77.5022 29.5982C76.5488 29.5982 75.6829 29.3387 74.9017 28.817C74.1204 28.2979 73.5696 27.7021 73.2439 27.0294V35.1541C73.2439 35.4799 73.0373 35.7818 72.6268 36.0651C72.2137 36.3458 71.7053 36.4888 71.0988 36.4888ZM76.1357 25.9251C76.6548 25.9251 77.1261 25.7795 77.5499 25.4855C77.9736 25.1942 78.3178 24.8182 78.5906 24.3653C78.8607 23.9099 78.9958 23.4332 78.9958 22.9353V20.2712C78.9958 19.7733 78.866 19.3073 78.6065 18.873C78.347 18.4387 77.9948 18.0758 77.5499 17.7845C77.105 17.4932 76.6124 17.3449 76.0722 17.3449C75.5955 17.3449 75.1453 17.4641 74.7242 17.7024C74.3005 17.9408 73.9483 18.2771 73.6676 18.7114C73.3869 19.1457 73.2439 19.6648 73.2439 20.2712V23.5206C73.2439 23.7377 73.3683 24.0343 73.6173 24.4157C73.8662 24.7944 74.2078 25.1413 74.6421 25.4564C75.0764 25.7715 75.5743 25.9278 76.1384 25.9278L76.1357 25.9251Z"
        
      />
      <path
        d="M92.3222 29.5984C91.0643 29.5984 89.907 29.2886 88.8451 28.6715C87.7831 28.0545 86.9278 27.2362 86.2763 26.2167C85.6275 25.1971 85.3018 24.1034 85.3018 22.9329V20.2688C85.3018 19.0983 85.6143 18.0098 86.2445 17.0009C86.8722 15.9946 87.7222 15.1869 88.7947 14.5804C89.8673 13.974 91.0431 13.6694 92.3222 13.6694C93.6012 13.6694 94.7771 13.9687 95.8496 14.5645C96.9221 15.1604 97.7775 15.9575 98.4183 16.9532C99.0566 17.9489 99.377 19.0559 99.377 20.2688V22.9329C99.377 24.0822 99.0513 25.1653 98.4025 26.1822C97.7536 27.2018 96.8903 28.0254 95.8178 28.653C94.7453 29.2806 93.5801 29.5958 92.3248 29.5958L92.3222 29.5984ZM92.3222 25.9253C92.8412 25.9253 93.3179 25.785 93.7522 25.5016C94.1865 25.2209 94.5334 24.8475 94.7929 24.3814C95.0525 23.9154 95.1822 23.4334 95.1822 22.9355V20.2714C95.1822 19.7736 95.0525 19.3075 94.7929 18.8732C94.5334 18.4389 94.1918 18.0761 93.7681 17.7848C93.3444 17.4934 92.8624 17.3451 92.3222 17.3451C91.7819 17.3451 91.3264 17.4855 90.8921 17.7689C90.4578 18.0496 90.1162 18.415 89.8673 18.8573C89.6183 19.3022 89.4939 19.7736 89.4939 20.2714V22.9355C89.4939 23.4334 89.6183 23.9154 89.8673 24.3814C90.1162 24.8475 90.4578 25.2209 90.8921 25.5016C91.3264 25.7823 91.8031 25.9253 92.3222 25.9253Z"
        
      />
      <path
        d="M104.088 29.3047C103.503 29.3047 103.016 29.1643 102.626 28.881C102.237 28.6002 102.041 28.2957 102.041 27.97V15.1632C102.041 14.7739 102.237 14.4588 102.626 14.2205C103.016 13.9821 103.503 13.863 104.088 13.863C104.628 13.863 105.079 13.9821 105.436 14.2205C105.794 14.4588 105.974 14.7739 105.974 15.1632V16.4953C106.212 16.0186 106.532 15.5684 106.932 15.1473C107.332 14.7236 107.793 14.3741 108.315 14.0907C108.834 13.81 109.387 13.667 109.972 13.667H110.981C111.392 13.667 111.749 13.8444 112.054 14.2046C112.356 14.5621 112.509 14.9911 112.509 15.489C112.509 15.9868 112.358 16.4476 112.054 16.8051C111.749 17.1626 111.392 17.3427 110.981 17.3427H109.972C109.366 17.3427 108.775 17.544 108.201 17.9439C107.626 18.3464 107.155 18.8972 106.787 19.6016C106.419 20.3061 106.233 21.1561 106.233 22.1519V27.97C106.233 28.2957 106.027 28.5976 105.616 28.881C105.203 29.1617 104.695 29.3047 104.088 29.3047Z"
        
      />
      <path
        d="M120.178 29.3043C118.229 29.3043 116.738 28.8806 115.708 28.0358C114.678 27.191 114.164 25.859 114.164 24.037V8.27488C114.164 7.88559 114.392 7.57046 114.847 7.33212C115.303 7.09378 115.769 6.97461 116.246 6.97461C116.765 6.97461 117.247 7.09378 117.691 7.33212C118.136 7.57046 118.359 7.88559 118.359 8.27488V13.8653H122.193C122.54 13.8653 122.805 14.0215 122.991 14.3366C123.173 14.6518 123.266 15.004 123.266 15.3933C123.266 15.7826 123.173 16.1348 122.991 16.4499C122.805 16.765 122.54 16.9213 122.193 16.9213H118.359V24.0397C118.359 24.6673 118.494 25.1122 118.764 25.3717C119.034 25.6312 119.505 25.761 120.178 25.761H121.447C122.053 25.761 122.503 25.9464 122.795 26.3145C123.089 26.6826 123.234 27.0851 123.234 27.5168C123.234 27.9723 123.089 28.3827 122.795 28.7508C122.503 29.1189 122.053 29.3043 121.447 29.3043H120.178Z"
        
      />
      <path
        d="M131.65 29.5984C130.392 29.5984 129.235 29.2886 128.173 28.6715C127.111 28.0545 126.256 27.2362 125.604 26.2167C124.956 25.1971 124.63 24.1034 124.63 22.9329V20.2688C124.63 19.0983 124.942 18.0098 125.573 17.0009C126.2 15.9946 127.05 15.1869 128.123 14.5804C129.195 13.974 130.371 13.6694 131.65 13.6694C132.929 13.6694 134.105 13.9687 135.178 14.5645C136.25 15.1604 137.106 15.9575 137.746 16.9532C138.385 17.9489 138.705 19.0559 138.705 20.2688V22.9329C138.705 24.0822 138.379 25.1653 137.731 26.1822C137.082 27.2018 136.218 28.0254 135.146 28.653C134.073 29.2806 132.908 29.5958 131.653 29.5958L131.65 29.5984ZM131.65 25.9253C132.169 25.9253 132.646 25.785 133.08 25.5016C133.515 25.2209 133.862 24.8475 134.121 24.3814C134.381 23.9154 134.51 23.4334 134.51 22.9355V20.2714C134.51 19.7736 134.381 19.3075 134.121 18.8732C133.862 18.4389 133.52 18.0761 133.096 17.7848C132.672 17.4934 132.191 17.3451 131.65 17.3451C131.11 17.3451 130.655 17.4855 130.22 17.7689C129.786 18.0496 129.444 18.415 129.195 18.8573C128.946 19.3022 128.822 19.7736 128.822 20.2714V22.9355C128.822 23.4334 128.946 23.9154 129.195 24.3814C129.444 24.8475 129.786 25.2209 130.22 25.5016C130.655 25.7823 131.131 25.9253 131.65 25.9253Z"
        
      />
      <path
        d="M143.45 36.4888C142.865 36.4888 142.378 36.3485 141.989 36.0651C141.599 35.7844 141.403 35.4799 141.403 35.1541V15.1655C141.403 14.7762 141.599 14.461 141.989 14.2227C142.378 13.9844 142.855 13.8652 143.419 13.8652C143.983 13.8652 144.443 13.9844 144.801 14.2227C145.158 14.461 145.336 14.7762 145.336 15.1655V16.14C145.704 15.5124 146.268 14.943 147.025 14.4346C147.783 13.9261 148.683 13.6719 149.724 13.6719C150.765 13.6719 151.792 13.9711 152.682 14.567C153.569 15.1628 154.268 15.9599 154.779 16.9557C155.288 17.9514 155.542 19.0583 155.542 20.2712V22.9353C155.542 24.0846 155.283 25.1678 154.761 26.1847C154.242 27.2042 153.548 28.0278 152.682 28.6554C151.816 29.2831 150.873 29.5982 149.854 29.5982C148.9 29.5982 148.034 29.3387 147.253 28.817C146.472 28.2979 145.921 27.7021 145.595 27.0294V35.1541C145.595 35.4799 145.389 35.7818 144.978 36.0651C144.565 36.3458 144.057 36.4888 143.45 36.4888ZM148.487 25.9251C149.006 25.9251 149.478 25.7795 149.901 25.4855C150.325 25.1916 150.669 24.8182 150.942 24.3653C151.212 23.9099 151.347 23.4332 151.347 22.9353V20.2712C151.347 19.7733 151.218 19.3073 150.958 18.873C150.699 18.4387 150.346 18.0758 149.901 17.7845C149.457 17.4932 148.964 17.3449 148.424 17.3449C147.947 17.3449 147.497 17.4641 147.076 17.7024C146.652 17.9408 146.3 18.2771 146.019 18.7114C145.738 19.1457 145.595 19.6648 145.595 20.2712V23.5206C145.595 23.7377 145.72 24.0343 145.969 24.4157C146.218 24.7944 146.559 25.1413 146.994 25.4564C147.428 25.7715 147.926 25.9278 148.49 25.9278L148.487 25.9251Z"
        
      />
      <path
        d="M160.32 10.4203C159.692 10.4203 159.149 10.2084 158.694 9.78738C158.238 9.36367 158.011 8.89494 158.011 8.37324C158.011 7.80917 158.238 7.3272 158.694 6.92732C159.149 6.52744 159.69 6.32617 160.32 6.32617C160.95 6.32617 161.485 6.52744 161.93 6.92732C162.375 7.3272 162.597 7.80917 162.597 8.37324C162.597 8.89229 162.375 9.36367 161.93 9.78738C161.485 10.2111 160.95 10.4203 160.32 10.4203ZM160.32 29.3047C159.714 29.3047 159.216 29.1643 158.824 28.881C158.434 28.6003 158.239 28.2957 158.239 27.97V15.1632C158.239 14.774 158.434 14.4588 158.824 14.2205C159.213 13.9821 159.711 13.863 160.32 13.863C160.929 13.863 161.403 13.9821 161.816 14.2205C162.227 14.4588 162.433 14.774 162.433 15.1632V27.97C162.433 28.2957 162.227 28.5976 161.816 28.881C161.403 29.1617 160.905 29.3047 160.32 29.3047Z"
        fill="#FF7A5A"
      />
      <path
        d="M168.964 29.5984C167.815 29.5984 166.824 29.2462 165.99 28.5418C165.156 27.8374 164.737 26.7489 164.737 25.2765C164.737 24.0425 165.058 23.0785 165.696 22.3847C166.334 21.6909 167.224 21.2036 168.36 20.9229C169.499 20.6422 170.815 20.4992 172.309 20.4992H173.641V20.0755C173.641 19.5988 173.548 19.1327 173.365 18.6772C173.18 18.2217 172.888 17.843 172.489 17.5385C172.089 17.2366 171.519 17.083 170.783 17.083C169.96 17.083 169.327 17.1651 168.882 17.3266C168.437 17.4882 168.095 17.6576 167.857 17.8298C167.619 18.0046 167.359 18.0893 167.076 18.0893C166.62 18.0893 166.268 17.8616 166.019 17.4061C165.77 16.9506 165.646 16.4845 165.646 16.0078C165.646 15.5311 165.921 15.1471 166.475 14.7896C167.028 14.4321 167.703 14.1567 168.506 13.9607C169.308 13.7648 170.087 13.6694 170.847 13.6694C172.536 13.6694 173.892 13.974 174.909 14.5804C175.926 15.1869 176.67 15.9681 177.136 16.9214C177.602 17.8748 177.835 18.9049 177.835 20.0092V27.9724C177.835 28.3405 177.639 28.6557 177.25 28.9152C176.861 29.1747 176.374 29.3045 175.788 29.3045C175.245 29.3045 174.798 29.1747 174.44 28.9152C174.083 28.6557 173.903 28.3405 173.903 27.9724V27.0297C173.318 27.7023 172.629 28.2981 171.84 28.8172C171.048 29.3362 170.089 29.5984 168.964 29.5984ZM170.656 26.4762C171.112 26.4762 171.567 26.3252 172.02 26.0207C172.475 25.7188 172.859 25.3269 173.174 24.8502C173.49 24.3735 173.646 23.8756 173.646 23.3539V22.6707H173.159C172.444 22.6707 171.76 22.7131 171.112 22.8005C170.463 22.8879 169.936 23.0759 169.536 23.3698C169.136 23.6638 168.935 24.1113 168.935 24.7178C168.935 25.3242 169.102 25.7691 169.438 26.0498C169.774 26.3305 170.179 26.4735 170.656 26.4735V26.4762Z"
        fill="#FF7A5A"
      />
    </g>
    <defs>
      <clipPath id="clip0_965_850">
        <rect width={177.838} height={44}  />
      </clipPath>
    </defs>
  </svg>
);
// export default IconLogo;


