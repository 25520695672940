export const getFormattedDate = (date: Date, lang = 'es') => {

    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString(lang, options);
    const formattedTime = date.toLocaleTimeString(lang, { hour: '2-digit', minute: '2-digit' });

    // Textos adaptados según el idioma
    if (lang === 'es') {
        return `Realizado el ${formattedDate} a las ${formattedTime}`;
    } else if (lang === 'en') {
        return `Completed on ${formattedDate} at ${formattedTime}`;
    }

    return `Date: ${formattedDate} Time: ${formattedTime}`; // Fallback
};