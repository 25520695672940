import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useGetApiNews } from "../../api/news.api";
import { News } from "../../models/news.model";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

import './page-search.css'
import CircularLoading from "../../shared/CircularLoading/CircularLoading";


const SearchPage = () => {
    const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const query = searchParams.get("query") || ""; // Obtener el término de búsqueda de la URL

  const { mutate, data: dataNewsList, isLoading, error } = useGetApiNews();

  useEffect(() => {
    mutate({}); // Llamar a la API para obtener todas las noticias
  }, [mutate]);

  
  // Filtrar las noticias por el término de búsqueda
  const filteredNews = query
    ? dataNewsList?.filter((news) =>
        news.title.toLowerCase().includes(query.toLowerCase())
      ) || []
    : dataNewsList || []; // Si no hay query, mostrar todas las noticias

  // Obtener la cantidad de resultados
  const resultCount = filteredNews.length;

  
  // Redirigir al hacer clic en una noticia
  const handleClick = (id: any) => {
    // setSearchTerm(""); // Limpiar el input
    navigate(`/noticia/${id}`); // Redirige a la página de la noticia
  };
    // Si la API está cargando o hubo un error, mostrar mensajes apropiados
    if (isLoading) return <div className="results_row results_row-loading"><CircularLoading /> </div>;
    if (error) return <p className="results_row" >Error: {error.message}</p>;

  return (
    <div className="results_row">
      <h2 className="results_title">{t('pageresults.title')}</h2>

      {resultCount > 0 ? (
        <p className="results_cant">{resultCount} {t('pageresults.results')} <b>{query}</b></p>
      ) : (
        <p className="results_cant">{t('pageresults.no_results')} <b>{query}</b></p>
      )}

      {resultCount > 0 && (
        <div className="results_container">
          {filteredNews.map((news:any ) => (
            <div className="results_item" key={news.id} onClick={() => handleClick(news.id)}>
              <div className="results_figure">
                <img src={news.image} alt={news.title} />
              </div>
              <div className="results_data">
                <h3 className="results_item_title">{news.title}</h3>
                <p className="results_item_text">{news.summary}</p>
              </div>
            </div>
          ))}
        </div>
      ) }
    </div>
  );
};

export default SearchPage;
