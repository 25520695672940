import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import PriceComparatorForm from "../../components/PriceComparator/PriceComparatorForm";
import PriceComparatorTable from '../../components/PriceComparator/PriceComparatorTable';
import VideoComponent from '../../core/Video/VideoAnimation';
import InitialAnimationPriceComparator from "../../components/PriceComparator/InitialAnimationPriceComparator";
import TransitionAnimation from "../../components/PriceComparator/TransitionAnimation";
import "./price-comparator.css";
import CustomToolTip from "../../core/CustomToolTip/CustomToolTip";
import ModalLegal from "../../components/PriceComparator/ModalLegal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const PriceComparatorPage: React.FC = () => {
  const {t} = useTranslation()
  const [showPriceComparatorFrm, setShowPriceComparatorFrm] = useState(true);
  const [showinitialFrm, setinitialFrm] = useState(true);
  const [showVideoComponent, setShowVideoComponent] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [showPriceComparatorAfterDelay, setShowPriceComparatorAfterDelay] = useState(false);
  const [open, setOpen] = useState(false);

  const handleComponentChange = () => {
    setShowPriceComparatorFrm(!setShowPriceComparatorFrm);
  };

  const handleStartButtonClick = () => {
    setShowVideoComponent(true);
  };

  const handleFullScreenChange = () => {
    setFullScreen(true);
    setinitialFrm(false);
    setShowPriceComparatorFrm(false);
  };

  let timer: NodeJS.Timeout | null = null;

  useEffect(() => {
    if (fullScreen) {
      timer = setTimeout(() => {
        setShowPriceComparatorAfterDelay(true);
      }, 34000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [fullScreen]);
  const skipIntro = () => {
    if (timer) {
      clearTimeout(timer); // Limpia el timeout si aún está activo
    }
    setShowPriceComparatorAfterDelay(true); // Muestra el contenido inmediatamente
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    


      <div className="exporter-test-information__container">
        {showinitialFrm ? (
          <div className='exporter-test-information__header'>
            <div className='price-calc__principal-title'>
              <span>{t('price_comparator.header.title')}</span>
              <CustomToolTip
                    title=""
                    tooltipText={
                        <span>
                          {t('price_comparator.header.tooltip')}{" "}
                          <button
                            onClick={handleClickOpen}
                            className="price-calc__tootip-link"
                          >
                            {t('price_comparator.header.button')}
                          </button>
                        </span>
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="font-size: 20px"
                    />
                  </CustomToolTip>

                  <span className="price-calc__tag">Demo</span>
            </div>
            <div className='exporter-test-information__principal-sub-title'>
              <p>{t('price_comparator.header.subtitle')}</p>
            </div>
          </div>
        ) : (
          null
        )}
        {fullScreen ? (
          <div className="exporter-test__row">
            <div className="col-12">
              {showPriceComparatorAfterDelay ? (
                <PriceComparatorTable />
              ) : (
                <>
                  <TransitionAnimation />
                  <button className="exporter-test__skip-intro" onClick={skipIntro}>{t('price_comparator.step_1.button_skip')}</button>
                </>
              )}

            </div>
          </div>
        ) : (
          <div className="exporter-test__row">
            <div className='exporter-test__flex'>
              {showPriceComparatorFrm ? (
                <PriceComparatorForm handleClickOpen={handleClickOpen} onComponentChange={handleComponentChange} onFullScreenChange={handleFullScreenChange} />
              ) : null}
            </div>
            <div className='exporter-test__fixed exporter-test-information__col-4'>
              {showVideoComponent ? (
                <VideoComponent showSrcImage={true} urlImagen={null}/>
              ) : (
                <InitialAnimationPriceComparator onStartButtonClick={handleStartButtonClick} />
              )}
            </div>
          </div>
        )}
      </div>
    
    <ModalLegal open={open} handleClose={handleClose} />
    </>
  );
};

export default PriceComparatorPage;
