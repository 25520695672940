import React from "react";
import { IconLogo } from "../Icons";
import { FechaComponente } from "../FechaComponente/FechaComponente";
import { useTranslation } from "react-i18next";
import './PdfDownload.css';
import { PdfDownloadProps } from "./PdfDownloadType";
import { t } from "i18next";


export const PdfDownload = ({ headerTitle = '', referenceName = '', className ='',  children }: PdfDownloadProps) => {
    const { i18n } = useTranslation(); 
    const lang = i18n.language; 
    
    return (
        <div id="divToPrint" className={`pdf_a4 hidden ${className || ""}`} >
            <div className="pdf_a4_header">
                <div className="pdf_a4_header_col">
                    <h2 className="pdf_a4_header_title">{headerTitle}</h2>
                    <div className="pdf_a4_header_data">
                        <span className="pdf_a4_header_info">{t('price_calculator.download.header_title')} {referenceName} </span>
                        <span className="pdf_a4_header_date"><FechaComponente lang={lang} /></span>
                    </div>
                </div>
                <div className="pdf_a4_header_col">
                    <IconLogo fill="#435563" height={22} className="pdf_a4_header_logo"  width={86} />
                </div>
            </div>
            {children}
        </div>
        
  );
};
