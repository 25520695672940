import React from 'react';
import { ProductsData } from '../../interfaces';
import { IconInstagram } from '../Icons/IconInstagram';
import { IconLinkedin } from '../Icons/IconLinkedin';
import { FechaComponente } from '../FechaComponente/FechaComponente';
import { t } from "i18next";
import { useTranslation } from 'react-i18next';
import { IconLogo } from '../Icons';
import { formatText } from '../../utils/formatText';

const ProductPages = ({ data, id , title, nameProduct }: { data: ProductsData[], id: number, title: string, nameProduct :string }) => {
    const { i18n } = useTranslation(); 
    const lang = i18n.language; 
  // Dividir los datos en páginas
  const totalData = data.length;
  const splitDataIntoPages = () => {
    const pages = [];
    let startIndex = 0;
    
    if (data.length > 0) {
      pages.push(data.slice(startIndex, 6)); // Primera página con 6 productos
      startIndex = 6;
    }
    
    while (startIndex < data.length) {
      pages.push(data.slice(startIndex, startIndex + 12)); // Resto de páginas con 12 productos
      startIndex += 12;
    }
    
    return pages;
  };

  const pages = splitDataIntoPages();

  return (
    <>
      {pages.map((page: Array<ProductsData>, pageIndex: number) => (
        <div key={pageIndex} className={`pdf_a4 pdf_a4_comparator ${pageIndex > 0 ? 'pdf_a4_hoja' : ''}`}>
            
        {pageIndex === 0 && (
            <>
                <div className="pdf_a4_header pdf_a4_header--mb">
                    <div className="pdf_a4_header_col">
                        <h2 className="pdf_a4_header_title">{t('price_calculator.header.title')}</h2>
                        <div className="pdf_a4_header_data">
                            <span className="pdf_a4_header_info">{t('price_calculator.download.header_title')} {nameProduct} </span>
                            <span className="pdf_a4_header_date"><FechaComponente lang={lang} /></span>
                        </div>
                    </div>
                    <div className="pdf_a4_header_col">
                        <IconLogo fill="#435563" height={22} className="pdf_a4_header_logo"  width={86} />
                    </div>
                </div>
                
            </>
        )}  
        <div className="pdf_a4_bg">
        {pageIndex === 0 && (
            <>
                
                <div className="pdf_a4_topcontent">
                    <h2 className="pdf_a4_topcontent_title">{title}</h2>
                    <p className="pdf_a4_topcontent_desc">{nameProduct}</p>
                    <img src={`../../assets/images/price-calculator-charts0${id + 1}.png`} className='pdf_a4_image_feature' alt="" />
                </div>
                <span className='pdf_a4_topcontent_total'>{totalData} {t('price_comparator.download.products')}</span>
            </>
        )}  
          
            <div className="pdf_a4_head">
              <div className="pdf_a4_head_col pdf_a4_col_image">
                <span className="pdf_a4_head_title">{t('price_comparator.download.product')}</span>
              </div>
              <div className="pdf_a4_head_col pdf_a4_col_data"></div>
              <div className="pdf_a4_head_col pdf_a4_col_prices">
                <span className="pdf_a4_head_title">{t('price_comparator.download.price_without_vat')}</span>
              </div>
              <div className="pdf_a4_head_col pdf_a4_col_prices">
                <span className="pdf_a4_head_title">{t('price_comparator.download.price_with_vat')}</span>
              </div>
              <div className="pdf_a4_head_col pdf_a4_col_brand">
                <span className="pdf_a4_head_title">{t('price_comparator.download.brand')}</span>
              </div>
              <div className="pdf_a4_head_col pdf_a4_col_flag">
                <span className="pdf_a4_head_title">{t('price_comparator.download.source')}</span>
              </div>
              <div className="pdf_a4_head_col pdf_a4_col_segment">
                <span className="pdf_a4_head_title">{t('price_comparator.download.segment')}</span>
              </div>
            </div>
            <div className="pdf_a4_pagina_productos">
              {page.map((item: ProductsData, itemIndex: number) => (
                <div key={itemIndex} className="pdf_a4_producto">
                  <div className="col pdf_a4_col_image pdf_a4_image_container">
                    <img
                      src={`./assets/images/${item.imagen}.png`}
                      alt={item.producto_name}
                      width={37}
                      height={37}
                      className="pdf_a4_producto-imagen"
                    />
                  </div>
                  <div className="col pdf_a4_col_data">
                    <div className='pdf_a4_col_data_top'>
                      <p className="pdf_a4_producto-name">{item.producto_name}</p>
                      <p className="pdf_a4_producto-subtitle">{item.producto_subtitle}</p>
                    </div>
                    <p className="pdf_a4_producto-description">{item.producto_description}</p>
                  </div>
                  <div className="col pdf_a4_col_prices pdf_a4_col_prices_filled">
                        <div className="pdf_a4_row">
                            <span className="pdf_a4_col_prices_num pdf_a4_col_prices-bold">{item.precio_sin_iva_gr} €</span>
                            <span className="pdf_a4_col_prices_size">{item.peso_gr}gr</span>
                        </div>
                        <div className="pdf_a4_row">
                            <span className="pdf_a4_col_prices_num pdf_a4_col_prices-bold">{item.precio_sin_iva_kg} €</span>
                            <span className="pdf_a4_col_prices_size">{item.peso_kg}kg</span>
                        </div>
                    </div>

                    <div className="col pdf_a4_col_prices ">
                        <div className="pdf_a4_row">
                            <span className="pdf_a4_col_prices_num ">{item.precio_con_iva_gr} €</span>
                            <span className="pdf_a4_col_prices_size">{item.peso_gr}gr</span>
                        </div>
                        <div className="pdf_a4_row">
                            <span className="pdf_a4_col_prices_num ">{item.precio_con_iva_kg} €</span>
                            <span className="pdf_a4_col_prices_size">{item.peso_kg}gr</span>
                        </div>
                    </div>
                  <div className="col pdf_a4_col_brand">
                    <span className="pdf_a4_col_brand_title">{item.brand_country}</span>
                    <img src={`./assets/images/${item.brand_flag}.png`} className='pdf_a4_col_brand_img' alt={item.brand_country} width={16} height={11} />
                  </div>
                  <div className="col pdf_a4_col_flag">
                    <span className="pdf_a4_col_flag_title">{item.brand_name}</span>
                    <img src={`./assets/images/${item.flag_source}.png`} className='pdf_a4_col_flag_img' alt={item.flag_source} width={16} height={11} />
                  </div>
                  <div className="col pdf_a4_col_segment">
                    <span className="pdf_a4_col_segment_title">{item.segment}</span>
                    <img src={`./assets/images/${item.segment_icon}.png`} className='pdf_a4_col_segment_img' alt={item.segment} width={11} height={11} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="pdf_a4_footer pdf_a4_footer--col">
            <div className="pdf_a4_footer_left">
              <span className="pdf_a4_footer_link">https://www.exportopia.eu/</span>
              <span className="pdf_a4_footer_ig"><IconInstagram /> exportopia</span>
              <span className="pdf_a4_footer_in"><IconLinkedin /> Exportopia</span>
            </div>
            <div className="pdf_a4_footer_paginator">
              <span className="pdf_a4_footer_paginator_text">{t('price_comparator.download.pagination.page')}</span>
              <span className="pdf_a4_footer_paginator_actual">{pageIndex + 1}</span>
              <span className="pdf_a4_footer_paginator_div"> {t('price_comparator.download.pagination.of')} </span>
              <span className="pdf_a4_footer_paginator_lenght">{pages.length + 1}</span>
            </div>
          </div>
        </div>
      ))}
      <div className="pdf_a4 pdf_a4_comparator pdf_a4_last">
      <div className="pdf_a4_footer ">
        <div className="pdf_a4_footer_top">

            <h3 className="pdf_a4_last_title" >{t('price_comparator.download.policy_title')}  </h3>
            <div className="pdf_a4_last_text" >
                <div dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_text_0')) }}  />
                <div dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_text_1')) }}  />
                <div dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_text_2')) }}  />
              
              <div>
                <h4 dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_title_1')) }}  />
                <div dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_text_2')) }}  />
              </div>
              <div>
                <h4 dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_title_2')) }}  />
                <div dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_text_3')) }}  />
              </div>
              <div>
                <h5 dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_subtitle_1')) }}  />
                <div dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_text_4')) }}  />
              </div>
              <div>
                <h5 dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_subtitle_2')) }}  />
                <div dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_text_5')) }}  />
              </div>
              <h4 dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_title_3')) }}  />
              <div>
                <h5 dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_subtitle_3')) }}  />
                <div dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_text_6')) }}  />
              </div>
              <div>
                <h4 dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_title_4')) }}  />
                <div dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_text_7')) }}  />
              </div>
              <p dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_text_8')) }}  />
              <div>
                <h5 dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_subtitle_4')) }}  />
                <div dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_text_9')) }}  />

              </div>
              <div>
                <h5 dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_subtitle_5')) }}  />
                <div dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_text_10')) }}  />
              </div>
              <div dangerouslySetInnerHTML={{ __html: formatText(t('price_comparator.download.policy_text_11')) }}  />

            </div>
                
        </div>
        <div className="pdf_a4_alert">
            <div className="icon">
            <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5640_372)">
                <path d="M13.6093 10.2382V8.53484H8.90097C8.87741 8.48315 8.85254 8.43145 8.82898 8.38105C10.1563 7.41048 11.4835 6.4412 12.8108 5.47062C12.8017 5.41893 12.7925 5.36853 12.7833 5.31683H10.4586C11.4835 4.2377 12.4273 3.24386 13.4522 2.16602C12.9993 2.14405 12.5896 2.12466 11.9692 2.09494C12.9326 1.33373 13.7637 0.678497 14.6224 0C15.4706 0.672035 16.29 1.3221 17.2456 2.07943C16.6579 2.11303 16.2547 2.1363 15.7887 2.16214C16.8136 3.20121 17.7966 4.19763 18.8687 5.28194H16.4563C16.434 5.33492 16.4118 5.38662 16.3895 5.43961C17.7207 6.41276 19.0532 7.38463 20.5376 8.47023H15.6722V10.224H22.2667C22.2851 10.4837 22.3073 10.6505 22.3073 10.8172C22.3086 13.8116 22.3086 16.8073 22.3047 19.8018C22.3047 20.0835 22.4107 20.4428 21.9107 20.4428C20.1488 20.4389 18.387 20.4415 16.5283 20.4415C17.3202 19.7901 18.0558 19.237 18.7299 18.618C19.4773 17.9304 19.9944 17.0917 20.1659 16.0707C20.3085 15.2293 20.0742 14.6594 19.315 14.2239C17.9249 13.4252 16.3686 13.1887 14.8057 13.0362C11.9037 12.7532 8.99783 12.5218 6.09456 12.253C5.73067 12.2194 5.36809 12.1108 5.01991 11.9945C4.52774 11.8304 4.60104 11.5215 4.94922 11.307C5.29348 11.0937 5.6914 10.9477 6.08409 10.8288C8.09334 10.2201 10.1615 10.1878 12.2388 10.2369C12.6721 10.2472 13.1067 10.2382 13.6106 10.2382H13.6093Z" fill="#387E22"/>
                <path d="M0 10.2638H5.02247C4.36538 10.4086 3.73053 10.5365 3.10092 10.6929C2.82866 10.7601 2.52105 10.8299 2.31947 11.0005C2.04197 11.2357 1.66892 11.5704 1.67285 11.8573C1.67678 12.1533 2.03019 12.6146 2.32471 12.7064C3.35486 13.0243 4.41512 13.2763 5.48192 13.4379C7.24377 13.7067 9.02265 13.8695 10.7884 14.1151C11.2112 14.1732 11.6353 14.3335 12.0189 14.5248C13.0215 15.0262 13.2165 15.8779 12.3605 16.5758C11.4848 17.2905 10.507 17.9793 9.45722 18.3709C7.36551 19.1502 5.21489 19.8028 3.04464 20.3366C2.1035 20.5679 1.05502 20.3754 0 20.3754V10.2612L0 10.2638Z" fill="#387E22"/>
                </g>
                <defs>
                <clipPath id="clip0_5640_372">
                <rect width="22.3125" height="20.4531" fill="white"/>
                </clipPath>
                </defs>
            </svg>

            </div>
            <p className="pdf_a4_alert_text">{t('exporterTest.download.alert_text')}</p>
        </div>
        
          <div className="pdf_a4_footer_bottom ">
            <div className="pdf_a4_footer_left">
                <span className="pdf_a4_footer_link">https://www.exportopia.eu/</span>
                <span className="pdf_a4_footer_ig"><IconInstagram /> exportopia</span>
                <span className="pdf_a4_footer_in"><IconLinkedin /> Exportopia</span>
                </div>
                <div className="pdf_a4_footer_paginator">
                <span className="pdf_a4_footer_paginator_text">{t('price_comparator.download.pagination.page')}</span>
                
                <span className="pdf_a4_footer_paginator_actual">{pages.length + 1}</span>
                <span className="pdf_a4_footer_paginator_div"> {t('price_comparator.download.pagination.of')} </span>
                <span className="pdf_a4_footer_paginator_lenght">{pages.length + 1}</span>
                </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPages;